body{
    background-image: url('./../../public/assets/img/background.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.black-color{
    color: black;
}
.green-color{
    color: #84b346;
}
.blue-color{
    color: #025d7e;
}
.info_color{
 color: #00afef;
}
.card_img_bg{
    color: #f8f8f2;
}
.bg-green{
   background-color:#84b346; 
}
.font-12{
    font-size: 12px;
}
.font-18{
    font-size: 18px;
}
.font-25{
    font-size: 25px;
}

.main-navigation a {
    color: #979899;
    display: block;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: normal;
    text-align: left;
}

.dropend .dropdown-toggle {
    color: #84b346;
    margin-left: 1em;
  }
  .dropdown-item:hover {
    background-color: white;
    color: #fff;
  }
  .dropdown .dropdown-menu {
    display: none;
  }
  .dropdown:hover > .dropdown-menu,
  .dropend:hover > .dropdown-menu {
    display: block;
    margin-top: 0.125em;
    margin-left: 0.125em;
  }

/* media  */
  @media screen and (min-width: 769px) {
    .dropend:hover > .dropdown-menu {
        position: absolute;
        top: 0px;
        left: 99%;
    }
    .dropend .dropdown-toggle {
      margin-left: 0.5em;
    }
  }
  /* new */
  .navbar-expand-lg .navbar-nav .drop-first{
    position: absolute;
    top: 30px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    border: none;
    width: 340px;
    border-bottom: 2px solid #80b451 !important;
}
.main-navigation a:hover{
    color: #84b346;
}
/* .drop-second .dropdown-item:hover{
    color: white !important;
} */
.navbar-expand-lg .navbar-nav .drop-second{
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    border: none;
    width: 345px;
    border-bottom: 2px solid #80b451 !important;
}
.border-bottom-1px{
    border-bottom: 1px solid #efebeb;
}
@media (max-width:1024px){
    .desktop-view{
    display: none;
    }
    .mobile-view{
        display: block !important;
    }
    .offcanvas-none{
        display: block !important;
    }
}
.mobile-view{
    display: none;
}
.new-acc .accordion-button:focus{
    border-color: white;
    outline: 0;
    box-shadow: none;
}
.new-acc .accordion-button:not(.collapsed){
    background-color: white;
    color: black;
}
.list-style-none{
    list-style-type: none;
}
.offcanvas-none{
    display: none;
}
@media (max-width:768px){
    .mobile-none{
        display: none;
    }
}
.header-sticky{
    position: sticky;
    top: 0px;
    z-index: 999;
}
.section-two{
    background-image: url('./../../public/assets/img/section-new2.jpg');
    background-size: cover;
    padding: 40px 0px;
}
.section-inner{
    background-color: #84b346;
    padding: 40px;
    border-radius: 10px;
}
/* new */

.banner_img1{
    background-image: url('./../../public/assets/img/banner-2.png');
    background-repeat: no-repeat;
    padding: 150px;  
    background-size: cover;
    width: 100%;
}
.banner_img2{
    /* background-image: url(/img/banner-2.png); */
    background-image: url('./../../public/assets/img/banner-new-1.jpg');
    background-repeat: no-repeat;
    padding: 150px;   
    background-size: cover;
    width: 100%;
}
.banner_img3{
    background-image: url('./../../public/assets/img/banner-new-2.jpg');
    background-repeat: no-repeat;
    padding: 150px;  
    background-size: cover;
    width: 100%;
}
.white-color{
    color: white;
}
.height-500{
    height: 600px;
}
.height-500-only{
    height: 500px;
}
.Explore_btn{
    background-color: #1a7e00;
    color: white;
    border-radius: 0px;
    padding: 12px 18px;
    font-weight: 500;
}
.More_aboutus{
    background-color: white;
    color: #1a7e00;
    border-radius: 0px;
    padding: 12px 18px;
    font-weight: 500;
}

/* header css scode start */
/* .navbar-expand-lg .navbar-nav .drop-first{
    position: absolute;
    top: 30px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    border: none;
} */
.main-navigation a:hover{
    color: #84b346;
}
/* .navbar-expand-lg .navbar-nav .drop-second{
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    border: none;
    border-bottom: 2px solid #80b451 !important;
} */
.font-wt-500{
    font-weight: 500;
}
.bg_section{
    background-color: #84b346;
}
.bg-gray{
    background-color: #e8e8dd;
    padding: 7px 10px;
}
.check-tick-green{
    color: #1A7E00;
}
.cms-tab-count {
    min-width: 26px;
    padding: 0 5px;
    height: 26px;
    line-height: 26px;
    border-radius: 13px;
    background-color: #fff;
    color: var(--cms-secondary-darken);
    font-size: 14px;
    font-weight: 700;
    color: black;
    text-align: center;
    transition: all 300ms ease-in-out 0ms;
    box-shadow: 0 0 0 5px rgba(255,255,255,.3);
}
.cms-tab-title .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: transparent;
}
.cms-tab-title .nav-link{
    color: black;
}
.contact .card-header{
    background-color: #84b346;
}
.content-left-contact{
    background-color: #00afef;  
}
.theme-btn {
    position: relative;
    display: block;
    width: 100%;
    padding: 24px 20px;
    line-height: 30px;
    color: #ffffff;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    background: #5748ca;
    border: 2px solid #5748ca;
    text-transform: capitalize;
    border-radius: 3px;
}
.height-180{
    height: 180px;
}
/*animation element*/
.animation-element {
    opacity: 0;
}

.animation-element.slide-up {
    opacity: 0;
    transition: all 500ms linear;
    transform: translate3d(0, 100px, 0);
}

.animation-element.slide-up.in-view {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
}

.animation-element.slide-left.testimonial:hover,
.animation-element.slide-left.testimonial:active {
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
}
@media (max-width: 768px){
    .banner_img1 {
        background-image: url('./../../public/assets/img/banner-1.png');
        background-repeat: no-repeat;
        padding: 0px !important;
        height: 530px;
    }
    .banner_img2{
        background-image: url('./../../public/assets/img/banner-2.png');
        background-repeat: no-repeat;
        padding: 0px  !important;   
        height: 530px;
    }
}
/* owl-carousel css start */
.owl-nav button {
    position: absolute;
    top: 50%;
    background-color: #000;
    color: #fff;
    margin: 0;
    transition: all 0.3s ease-in-out;
}
    .owl-nav button.owl-prev {
        left: 0;
    }

    .owl-nav button.owl-next {
        right: 0;
    }

.owl-dots {
    text-align: center;
    padding-top: 15px;
}

    .owl-dots button.owl-dot {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        display: inline-block;
        background: #ccc;
        margin: 0 3px;
    }

        .owl-dots button.owl-dot.active {
            background-color: #000;
        }

        .owl-dots button.owl-dot:focus {
            outline: none;
        }

.owl-nav button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.38) !important;
}

.owl-prev span {
    font-size: 70px;
    position: relative;
    top: -5px;
}.owl-next span {
    font-size: 70px;
    position: relative;
    top: -5px;
}

.owl-nav button:focus {
    outline: none;
}
.carousel_img{
    width: 25%  !important;
}
/* owl-carousel css end*/

/* footer css start */
.footer-bg{
    background-color: #012217;
}
.bg_contai_footer{
    background-color: #1c3930; 
}
.address-icon {
    color: white;
}
.contact_us{
 
    border: 1px solid #84b346;
    color: #84b346;
    padding: 10px 62px;
    font-weight: 600;
}
.contact_us:hover{
    background-color: #84b346 !important;
    color: white;
    background-color: white;
 }
 .contact_us.active{
    background-color: #84b346;
    color: white;
    padding: 10px 62px;
    font-weight: 600;
 }
.four-line{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
  @media (max-width: 768px){
    .login_icon{
        width: 25%  !important;
    }   
  }


  
.blob{
    box-shadow:0 0 0 0 rgb(17 57 134);
    transform: scale(1);
    }
    .blob:hover{
    animation: pulse-black 2s infinite;
    }
    @keyframes pulse-black {
    0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(26, 226, 143, 0.7);
    }
    
    70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(226, 26, 26, 0);
    }
    
    100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(226, 26, 26, 0);
    }
    }
    /* button for top section start*/
    #myBtn {
    
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    border: none;
    outline: none;
    color: white;
    cursor: pointer;
    padding: 12px 15px;
    border-radius: 50%;
    background: #00afef;
    }
    
    #myBtn:hover {
    background-color: #84b346;
    }
    @media (max-width: 500px){
        .header-sticky{
            position: revert;
            top: 0px;
            z-index: 999;
        }
        .mobile-center{
            text-align: center;
        }
        .height-180{
            height: auto;
        }
        .font-12{
            font-size: 15px;
        }
        .height-150{
            height: auto !important;
        }
        .bg-green-color img{
            width: 25% !important;
        }
        .height-500-bod{
            height: auto !important;
        }
        .breadcrum-invertor{
            padding: 50px 0px !important;
        }
        .bg-green-color{
            height: auto !important;
        }
        .bg-sectionnew{
            padding: 60px 0px 0px 0px !important;
        }

    }
    .border-radius-20{
        border-radius: 20px;
    }
    .bg-green-color{
        background-color: #467c14;
        color: white;
        height: 160px;

    }
    .bg-green-color:hover {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
        transition: all 500ms ease;
        background-color:#00aeec;
    }
    .height-150{
        height: 150px;
    }
    /* 15-4-2024 css start */
    @media (max-width: 789px){
        .breadcrum {
            background-image: url('./../../public/assets/img/breadcrum-img.png');
            background-size: cover;
            background-repeat: no-repeat;
            padding: 50px  !important;
            width: 100%;
        }
    }

        /* About us code css */
        .breadcrum_anchor{
    color: black;
        }
        .breadcrum{
            background-image: url('./../../public/assets/img/breadcrum-img.png');
            background-size: cover;
            background-repeat: no-repeat;
            padding: 150px 0px;
            width: 100%;
        }
        .breadcrum-news{
            background-image: url('./../../public/assets/img/media-banner.jpg');
            background-size: cover;
            background-repeat: no-repeat;
            padding: 150px 0px;
            width: 100%;
        }
        .breadcrum-shareholder{
            background-image: url('./../../public/assets/img/investor-report-banner.jpg');
            background-size: cover;
            background-repeat: no-repeat;
            padding: 150px 0px;
            width: 100%;
        }
        .breadcrum-contact{
            background-image: url('./../../public/assets/img/contact-banner.jpg');
            background-size: cover;
            background-repeat: no-repeat;
            padding: 150px 0px;
            width: 100%;
        }
        .breadcrum-career{
            background-image: url('./../../public/assets/img/career-banner.jpg');
            background-size: cover;
            background-repeat: no-repeat;
            padding: 150px 0px;
            width: 100%;
        }
        .breadcrum-document{
            background-image: url('./../../public/assets/img/document-banner.jpg');
            background-size: cover;
            background-repeat: no-repeat;
            padding: 150px 0px;
            width: 100%;
        }
        .breadcrum-Announcement{
            background-image: url('./../../public/assets/img/Announcement-banner.jpg');
            background-size: cover;
            background-repeat: no-repeat;
            padding: 150px 0px;
            width: 100%;
        }
        .breadcrum-ongoing{
            background-image: url('./../../public/assets/img/ongoing-banner.png');
            background-size: cover;
            background-repeat: no-repeat;
            padding: 150px 0px;
            width: 100%;
        }

    /* about us */
    div[data-background-image] {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    .cms-btn-video.layout-3 .cms-icon {
        width: 86px;
        height: 86px;
        line-height: 86px;
        background-color: #fff;
        color: green;
        border-radius: 50%;
        transition: all 300ms linear 0ms;
    }
    .cms-btn-video .cms-icon {
        text-align: center;
    }
    .cms-radius-trbr-16, .elementor img.cms-radius-trbr-16 {
        border-radius: 0 16px 16px 0;
    }
    .p-tb-200 {
        padding-top: 200px;
        padding-bottom: 200px;
    }
    .cms-btn-video.layout-3 {
        width: 170px;
        min-height: 170px;
        background-color: transparent;
        color: #fff;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid #fff;
    }
    .cms-transition {
        transition: all 300ms linear 0ms;
    }
    .cms-btn-video {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 700;
    }
    .m-lr-auto {
        margin-left: auto;
        margin-right: auto;
    }
    .cms-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
    .empty-none:empty {
        display: none;
    }
    .cms-btn-video.layout-3:hover .cms-icon {
        box-shadow: 0 0 0 10px #fff;
    }
    .video-about{
        width: 100%;
        height: 500px;
    }
    .line-height-25{
        line-height: 25px;
    }
    .cms-lists-3 {
        padding-bottom: 28px;
    }
    .hover-icon-bounce:hover .cms-icon {
        animation: icon-bounce 1s infinite linear;
    }

    @keyframes icon-bounce {
    0%,100%,20%,50%,80% {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0)
    }

    40% {
        -webkit-transform: translateY(-10px);
        -ms-transform: translateY(-10px);
        transform: translateY(-10px)
    }

    60% {
        -webkit-transform: translateY(-5px);
        -ms-transform: translateY(-5px);
        transform: translateY(-5px)
    }
}
.cursor-pointer{
    cursor: pointer;;
}
.height-500{
    height: 500px;
    overflow-y: scroll;
}
.height-500::-webkit-scrollbar {
    display: none;
}
.height-500 {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .card-borad{
background-color: #e8e9eb;
  }
  .card-inner{
    box-shadow: 0px 5px 43px 0px rgba(17, 29, 25, 0.06);
    position: relative;
  }

.box-inner-borad{
    background-color:#84b346;
    border-radius: 5px;
}
.bottom-10{
    bottom: 10px;
}
.text-transform-uppercase{
    text-transform: uppercase;
}
.back_Color{
    background-color: #84b346;
}
.blue-color{
    color: #00afef;
}

.pointer {cursor: pointer;}

.btn_close{
background-color: #84b346;
color: white;
}

/* new of bod */
.boradofdire{
transition-delay: .2s;
}
.boradofdire {
opacity: 1;
transform: translateZ(0);
}
.borad-member{
transition: all 0.6s;
}
.borad-member .borad-member-img img {
max-width: 100%;
/* height: auto; */
border-radius: 4px;
}
.borad-member * {
transition: all 0.6s;
}
.borad-member-content {
background: rgb(0 174 236 / 92%);
border-radius: 4px;
position: absolute;
left: 50%;
transform: translateX(-50%);
width: 100%;
bottom: 20px;
max-width: 330px;
padding: 15px;
height: 145px;
text-align: center;
overflow: hidden;
}

.borad-member-content h4 {
font-size: 20px;
line-height: 29px;
letter-spacing: 0.01em;
text-transform: capitalize;
color: #ffffff;
font-weight: 400;
position: relative;
margin-bottom: 15px;
}
.borad-member-content h3::before {
    content: "";
    width: 52px;
    height: 2px;
    background: white;
    display: block;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
}
.borad-member-content p {
font-size: 14px;
line-height: 16px;
letter-spacing: 0.01em;
text-transform: capitalize;
color: #ffffff;
font-weight: 300;
margin: 0;
}
.readmore {
display: flex;
align-items: center;
justify-content: center;
margin-top: 15px;
}
.readmore .resourceButton {
margin-top: 0;
}
.resourceButton {
display: block;
text-align: right;
margin-top: 30px;
}
.readmore .resourceButton a {
position: relative;
bottom: -50px;
opacity: 0;
font-weight: 300;
font-size: 12.05483px;
line-height: 8px;
padding: 12px 18px;
}
.resourceButton a {
color: #fff;
border: 1px solid #fff;
padding: 16px 40px;
display: inline-block;
border-radius: 50px;
position: relative;
}
.readmore .resourceButton a img {   
width: 15px;
}
.resourceButton a img { 
margin-left: 4px;
}
.borad-member:hover .borad-member-content {
height: 150px;
}
.borad-member:hover .readmore .resourceButton a {
bottom: 0;
opacity: 1;
}
.borad-member .resourceButton a:hover {
    background: #84b346;
    border: 1px solid #84b346;
}
.height-500-bod{
    height: 500px;
}
.text-justify{
    text-align:justify;
}
.boardMemeberBox .box {
    position: relative;
    padding: 0 10px;
}
.boardMemeberBox .box img {
    width: 100%;
    border-radius: 4px;
}
.boardMemeberBox .box .boxContent {
    overflow: hidden;
    height: 70px;
    padding-top: 10px;
    transition: all 0.3s;
    border-radius: 4px;
}
.boardMemeberBox .box .boxContent {
    position: absolute;
    background:  rgb(0 174 236 / 92%);
    color: #fff;
    bottom: 20px;
    width: 80%;
    left: 50%;
    transform: translate(-50%, 0);
    height: 115px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.boardMemeberBox .box .boxContent h5 {
    position: relative;
}
.boardMemeberBox .box .boxContent h5 {
    margin: 0;
    font-weight: 500;
}
.boardMemeberBox .box .boxContent h6 {
    position: relative;
}
.boardMemeberBox .box .boxContent h6 {
    margin: 0;
    font-weight: 500;
}
.boardMemeberBox .box .boxContent p {
    position: relative;
    top: 100px;
    transition: all 0.3s;
}
.boardMemeberBox .box .boxContent p {
    font-weight: 300;
    position: relative;
    padding-top: 10px;
}
.boardMemeberBox .box:hover .boxContent {
    height: 130px;
}
.boardMemeberBox .box .boxContent h5:after {
    position: absolute;
    bottom: -10px;
    left: 50%;
    width: 50px;
    height: 1px;
    background: white;
    content: "";
    transform: translate(-50%, 0);
}
.boardMemeberBox .box:hover p {
    top: 0;
}
.services .nav-pills .nav-link{
    border-radius: 13px;
    background: #193233;
    color: white;
}
.services  .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #80b451;
}
.services .nav-link {
    padding: 1rem 1rem !important;
}
.services .nav-item{
    margin-left: 5px;
}
.dark-color {
    color: #163233;
}
/* .card-light-bg{
    background-color: #163233;
    color: white;
    border-radius: 12px;
    margin: 15px;
    top:0;
    position:relative;
    -webkit-transition: top 0.5s ease-out;
    -moz-transition: top 0.5s ease-out;
    -o-transition: top 0.5s ease-out;
} */
.card-light-bg1:hover {
    top: -12px;
}
.card-light-bg2:hover {
    top: -12px;
}
.card-light-bg1:hover {
    top: -12px;
}
.card-light-bg3:hover {
    top: -12px;
}
.card-light-bg4:hover {
    top: -12px;
}
/*  */
.card-light-bg1{
    background-image: url('./../../public/assets/img/service/image-1-1.png');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 12px;
    margin: 15px;
    top:0;
    position:relative;
    -webkit-transition: top 0.5s ease-out;
    -moz-transition: top 0.5s ease-out;
    -o-transition: top 0.5s ease-out;
}
.card-light-bg2{
    background-image: url('./../../public/assets/img/service/image-1-2.png');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 12px;
    margin: 15px;
    top:0;
    position:relative;
    -webkit-transition: top 0.5s ease-out;
    -moz-transition: top 0.5s ease-out;
    -o-transition: top 0.5s ease-out;
}
.card-light-bg3{
    background-image: url('./../../public/assets/img/service/image-1-3.png');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 12px;
    margin: 15px;
    top:0;
    position:relative;
    -webkit-transition: top 0.5s ease-out;
    -moz-transition: top 0.5s ease-out;
    -o-transition: top 0.5s ease-out;
}
.card-light-bg4{
    background-image: url('./../../public/assets/img/service/image-1-4.png');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 12px;
    margin: 15px;
    top:0;
    position:relative;
    -webkit-transition: top 0.5s ease-out;
    -moz-transition: top 0.5s ease-out;
    -o-transition: top 0.5s ease-out;
}
/*  */
.card-new{
    overflow: hidden;
}
.card-new img{
    transition: 0.4s;
}
.card-new:hover img {
    transform: scale(1.1);
}
.report div {
    display: inline-table;
    margin: 0 5px;
}
.report p {
    font-size: 18px !important;
    margin-bottom: 10px;
}
.EnquiryBtn {
    background: #193233;
    font-weight: 500;
    padding: 15px;
    border-radius: 50px;
    margin-top: 20px;
    font-size: 16px;
    transition: .7s;
    display: inline-block;

    text-decoration: none;
    color: white;
}
.EnquiryBtn:hover{
    background-color: #80b451;
    color: white;
}
.font-18{
    font-size: 18px;
}
.font-500{
    font-weight: 500;
}
.select .form-select:focus {
    border-color: none;
    outline: none;
    box-shadow: none;
}
.font-40{
    font-size: 40px;
}
.pagination .page-link{
    color: black;
}
.breadcrum-invertor{
    background-image: url('./../../public/assets/img/financial\ statement\ images\ \(1\).jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 150px 0px;
    width: 100%;
}
.financial table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  .financial td, th {
    border: 1px solid #7c7b7b;
    text-align: center;
    padding: 8px;
  }

.icon_why {
    display: flex;
    justify-content: center;
    margin: 10px 0px;
    height: 50px;
    width: 50px;
    background: #80b451;
    border-radius: 50%;
    margin: 0 auto;
}
.why_para a{
    background: #80b451;
    padding: 5px;
    border-radius: 5px;
    color: white;
    display: inline;
}
.why_para a:hover{
    border: 1px solid #80b451;
    color: #80b451;
    border-radius: 5px;
    background-color: white;

}
.card_property_why {
    -webkit-box-shadow: 0px 0px 10px -2px rgb(125 125 125);
    box-shadow: 0px 0px 40px 0px rgb(212 212 212);
    background-color: #fff;
    border-bottom: 2px solid #80b451;
    margin: 2px 0 25px;
    padding: 10px 10px 10px;
    position: relative;
    text-align: center;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    /* height: 233px; */
    justify-content: space-around;
}
.card_property_why:hover {
    background: #f5f5f5 none repeat scroll 0 0;
}
.bg-investor{
    background: white;
    padding: 10px;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 600;
}
.circle-icon{
    width: 50px;
    height: 50px;
    background-color: #e4faea;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #80b451;
    font-size: 22px;
    border: 2px solid #80b451;
}
.icon-material:hover .circle-icon{
    background-color: #80b451;
    color: white;
}
.icon-material:hover .circle-icon-inner{
    background-color: #80b451;
    color: white;
}
.icon-material .circle-icon-inner{
    width: 35px;
    height: 35px;
    background-color: #e4faea;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #80b451;
    font-size: 18px;
    border: 2px solid #80b451;
}
.box-highlight{
    background-color: white;
    padding: 15px;
    border-radius: 30px;
    border-bottom: 3px solid #00aeec;
    color: dark;
}
.box-highlight a{
    color: black;
}
.box-highlight:hover a{
    color: white;
}

.box-highlight:hover{
    transition: .7s;
    background-color: #00aeec;
}
.card-ongoing{
    position: relative;
}
.card-ongoing .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    transition: background 0.5s ease;
  }
  .card-ongoing .overlay {
    display: block;
    background: rgba(0, 0, 0, .3);
  }
  .card-ongoing img {
    position: relative;
    width: 100%;
    height: 300px;
    left: 0;
  }
  .card-ongoing .title {
    position: absolute;
    width: 100%;
    left: 0;
    top: 120px;
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
    color: white;
    z-index: 1;
    transition: top .5s ease;
  }
  
  .card-ongoing:hover .title {
    top: 90px;
  }
  
  .card-ongoing .button {
    position: absolute;
    width: 100%;
    left:0;
    top: 180px;
    text-align: center;
    opacity: 0;
    transition: opacity .35s ease;
  }
  
  .card-ongoing .button a {
    width: 200px;
    padding: 12px 48px;
    text-align: center;
    color: white;
    border: solid 2px white;
    z-index: 1;
  }
  
  .card-ongoing:hover .button {
    opacity: 1;
  }
  .list-style-sqaure{
    list-style-type: square;
  }
  .box-leader {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15), 0 3px 3px rgba(0, 0, 0, 0.15);
    border-left: 3px solid #00aeec;
}
.box-leader-box{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15), 0 3px 3px rgba(0, 0, 0, 0.15);
}
.font-25{
    font-size: 25px;
}
.box-contact{
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 50px;
    background-color: white;
    border-radius: 10px;
}
.box-contact input:hover{
    border: 1px solid #00aeec;
}
.box-contact textarea:hover{
    border: 1px solid #00aeec;
}
.box-contact input:focus{
 border-color: none;
    outline: 0;
    box-shadow: none;
}
.btn-outline-submit{
    padding: 13px 30px 13px 30px;
    font-family: "Poppins", Sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #ffffff;
    background-color: #3A9E1E;
    border-style: none;
    border-radius: 5px 5px 5px 5px;
}
.btn-outline-submit:hover{
    background-color: #00aeec;
    color: #ffffff;
}
.map-contact{
    width: 100%;
    height: 400px;
    margin-bottom: 0px;
}
.height-300{
    height: 300px;
}

.news .myImg {
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
}

.news .myImg:hover {
    opacity: 0.7;
}

.news .modal {
    display: none;
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 999;
}

.news .modal-content {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 600px;
    height: 500px;
}

.news #caption {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    text-align: center;
    color: #ccc;
    padding: 10px 0;
    height: 150px;
}

.news .modal-content,
#caption {
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 0.6s;
    animation-name: zoom;
    animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
    from {
        -webkit-transform: scale(0)
    }

    to {
        -webkit-transform: scale(1)
    }
}

@keyframes zoom {
    from {
        transform: scale(0)
    }

    to {
        transform: scale(1)
    }
}

.news .close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

.news .close:hover,
.news .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

@media only screen and (max-width: 700px) {
    .modal-content {
        width: 100%;
    }
}

.box-disclou{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15), 0 3px 3px rgba(0, 0, 0, 0.15);
    padding: 10px;
    border: 1px solid #80b451;
    border-radius: 10px;
    font-size: 18px;
  
}
.box-disclou a{
    color: black;
    text-decoration: none;
    font-weight: 500;
}
.box-disclou a:hover{
    color: #80b451;
    text-decoration: revert;
    font-weight: 400;
}

.card-about{
    /* display: flex; */
    transition: 0.3s;
    border-radius: 15px 15px 15px 15px;
    box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.1);
}
.card-about-middle{
    background-image: linear-gradient(135deg, #84b346 0%, #00aeec 100%);
    color: white;
    transition: 0.3s;
    border-radius: 15px 15px 15px 15px;
    box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.1);
}

/* ongoing card */
.card-going{
    overflow: hidden;
    cursor: pointer;
}
.card-going img{
    height: 300px;
    width: 100%;
    border-radius: 10px;
    transition-delay: .1s;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-property: all;
  
}
.box-ongoing{
    background-color: white;
    padding: 15px;
    margin-top: -60px;
    z-index: 1;
    position: relative;
    border-radius: 5px;
}
.card-going img:hover {
    border-radius: 10px;
    transform: scale(1.1) rotate(2deg);
}
.font-400{
    font-weight: 400;
}
.height-200{
    height: 200px;
}
.cultural_modal .close {
    color: white;
    position: absolute;
    top: 50px;
    right: 25px;
    font-size: 35px;
    font-weight: bold;
  }
  
  .cultural_modal .close:hover,
  .cultural_modal .close:focus {
    color: #999;
    text-decoration: none;
    cursor: pointer;
  }
  
  .cultural_modal .mySlides {
    display: none;
  }
  
  .cultural_modal .cursor {
    cursor: pointer;
  }
  
  /* Next & previous buttons */
  .cultural_modal .prev,
  .cultural_modal .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -50px;
    color: white;
    font-weight: bold;
    font-size: 20px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    -webkit-user-select: none;
  }
  
  /* Position the "next button" to the right */
  .cultural_modal .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }
  
  /* On hover, add a black background color with a little bit see-through */
  .cultural_modal .prev:hover,
  .cultural_modal .next:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  /* Number text (1/3 etc) */
  .cultural_modal .numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
  }

  /* The Modal (background) */
  .gallery-modal .modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 999; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
  }
  
  /* Modal Content (Image) */
  .gallery-modal  .modal-content {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 1000px;
  }
  
  /* Caption of Modal Image (Image Text) - Same Width as the Image */
  .gallery-modal  #caption {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    text-align: center;
    color: #ccc;
    padding: 10px 0;
    height: 150px;
  }
  
  /* Add Animation - Zoom in the Modal */
  .gallery-modal .modal-content, #caption {
    animation-name: zoom;
    animation-duration: 0.6s;
  }
  
  @keyframes zoom {
    from {transform:scale(0)}
    to {transform:scale(1)}
  }
  
  /* The Close Button */
  .gallery-modal .close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
  }
  
  .gallery-modal .close:hover,
  .gallery-modal .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }
  
  /* 100% Image Width on Smaller Screens */
  @media only screen and (max-width: 700px){
    .gallery-modal  .modal-content {
      width: 100%;
    }
  }
  .height-500{
    height: 500px;
  }
  
  .card-gallery{
    overflow: hidden;
    cursor: pointer;
}
.card-gallery img{
    transition-delay: .1s;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-property: all;
  
}
.card-gallery img:hover {
    opacity: 0.8;
}
.height-400 {
    height: 400px;
}

.bg-other{
    background-image: url('./../../public/assets/img/main-section.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 30px 0px;
}
.other .card{
    transition: all 300ms linear 0ms;
    border-radius: 10px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 40px;
    padding-bottom: 40px;
    margin: 0 auto;
}
.margin-auto{
    margin: 0 auto;
}
.other .card:hover {
    transition: all 300ms linear 0ms;
    background-color: #182822 !important;
    color: #fff;
}
.other .card:hover img{
    animation: icon-bounce 1s infinite linear;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0) saturate(7408%) hue-rotate(298deg) brightness(105%) contrast(97%);
}
@keyframes icon-bounce {
    0%,100%,20%,50%,80% {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0)
    }

    40% {
        -webkit-transform: translateY(-10px);
        -ms-transform: translateY(-10px);
        transform: translateY(-10px)
    }

    60% {
        -webkit-transform: translateY(-5px);
        -ms-transform: translateY(-5px);
        transform: translateY(-5px)
    }
}

/* card */
.project-block-one .inner-box {
    position: relative;
    display: block;
    overflow: hidden;
}
.project-block-one .inner-box .image-box {
    position: relative;
    display: block;
    overflow: hidden;
    border-radius: 20px;
}
.project-block-one .inner-box .image-box:before {
    position: absolute;
    content: '';
    background: #023827;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    opacity: 0;
    z-index: 1;
    transition: all 500ms ease;
}
.project-block-one .inner-box .image-box img {
    width: 100%;
    border-radius: 20px;
    transition: all 500ms ease;
    height: 300px;
}
.project-block-one .inner-box .content-box {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 2;
}
.project-block-one .inner-box .content-box .view-btn {
    position: absolute;
    left: 50%;
    top: 42%;
    transform: translate(-50%, -50%);
}
.project-block-one .inner-box .content-box .view-btn a {
    position: relative;
    display: inline-block;
    width: 70px;
    height: 70px;
    line-height: 70px;
    font-size: 30px;
    color: #fff;
    background: #3a9e1e;
    text-align: center;
    border-radius: 50%;
    transform: scale(0, 0);
}
.project-block-one .inner-box .content-box .text-box {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    padding-left: 40px;
    opacity: 0;
    transition: all 500ms ease;
}
.project-block-one .inner-box .content-box .text-box p {
    display: block;
    color: #fff;
    margin-bottom: 5px;
}
.project-block-one .inner-box .content-box .text-box h3 {
    display: block;
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
    color: white;
}
.project-block-one .inner-box:hover .image-box:before {
    opacity: 0.7;
}
.project-block-one .inner-box:hover .image-box img {
    transform: scale(1.05);
}

.project-block-one .inner-box:hover .content-box .text-box {
    bottom: 40px;
    opacity: 1;
}
.project-block-one .inner-box:hover .content-box .view-btn a {
    transform: scale(1, 1);
}


/* new */
.logo img {
    max-height: 55px;
}
.custom-loader {
    width: 60px;
    height: 20.5px;
    border: 1px solid #80b451;
    border-radius: 50%;
    display: grid;
    animation: h2 4s infinite linear;
}
.custom-loader:before,
.custom-loader:after {
  content:"";
  grid-area: 1/1;
  border:inherit;
  border-radius: 50%;
  animation: inherit;
  animation-duration: 6s;
}
.custom-loader:after {
  --s:-1;
}
@keyframes h2 {
    100% {transform:rotate(calc(var(--s,1)*1turn))}
 }
 #content1 {
    position: relative;
    animation-name: content1;
    animation-duration: 3s;
    animation-fill-mode: forwards;
  }
  
  /* this section tells it to animate from the left to right */
  @keyframes content1 {
    0% {left: -1000px;}
    100% {left: 0px;}
  }
  
  .ban-1{
    background-image: url('./../../public/assets/img/ban-2.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 150px 0px;
  }
  .ban-2{
    background-image: url('./../../public/assets/img/ban-3.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 150px 0px;
  }
  .ban-3{
    background-image: url('./../../public/assets/img/ban-1.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 150px 0px;
  }
  .ban-4{
    background-image: url('./../../public/assets/img/ban-4.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 150px 0px;
  }
  .card-section{
    position: relative;
    display: block;
    background: #fff;
    padding: 30px 30px 0px 30px;
    box-shadow: 0px 2px 70px rgba(0, 0, 0, 0.1);
    border-radius: 10px 10px 100px 100px
  }
  .service-block-one .inner-box {
    position: relative;
    display: block;
    background: #fff;
    padding: 30px 30px 0px 30px;
    box-shadow: 0px 2px 70px rgba(0, 0, 0, 0.1);
    border-radius: 10px 10px 100px 100px;
}
.service-block-one .inner-box .image-box {
    position: relative;
    display: block;
    overflow: hidden;
    border-radius: 10px 10px 100px 100px;
}
.service-block-one .inner-box .image-box img {
    width: 100%;
    border-radius: 10px 10px 100px 100px;
    transition: all 500ms ease;
    height: 210px;
}
.service-block-one .inner-box .lower-content {
    position: relative;
    display: block;
    padding: 58px 10px 35px 10px;
}
.service-block-one .inner-box .lower-content .icon-box {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -50px;
    width: 127px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    font-size: 50px;
    color: var(--theme-color);
}
.service-block-one .inner-box .lower-content .icon-box .icon-bg {
    position: absolute;
    left: -36px;
    top: -3px;
    width: 202px;
    height: 173px;
    background-repeat: no-repeat;
    z-index: -1;
}
.service-block-one .inner-box .lower-content .icon-box .icon {
    position: relative;
    display: inline-block;
    transition: all 500ms ease;
    color: #80b451;
}
.service-block-one .inner-box .lower-content h3 {
    display: block;
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    margin-bottom: 12px;
}
.service-block-one .inner-box .lower-content h3 a {
    display: inline-block;
    color: #101A30;
    text-decoration: none;
}
.service-block-one .inner-box .lower-content p {
    color: #101A30;
}
.service-block-one .inner-box .lower-content .link {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -35px;
}
.service-block-one .inner-box .lower-content .link a {
    position: relative;
    display: inline-block;
    width: 70px;
    height: 70px;
    line-height: 74px;
    background: #fff;
    text-align: center;
    font-size: 20px;
    border-radius: 50%;
    color: #101A30;
    box-shadow: 0px 0px 70px rgba(0, 0, 0, 0.15);
}
.service-block-one .inner-box:hover .image-box img {
    transform: scale(1.05);
}
.service-block-one .inner-box:hover .lower-content .icon-box .icon {
    transform: rotateY(-180deg);
}


.service-border{
    border-bottom-right-radius: 35px;
    border-bottom-left-radius: 35px;
    border-top-right-radius: 35px;
    border-top-left-radius: 35px;
}
.shadow-service{
    box-shadow: 0px 0px 10px 0px #b3b3b3;
}
.service-btn2{
    background-color: #84b346; 
    color: white;
}
.service-btn2:hover {
    background-color: rgb(132, 179, 70);
    color: white;
}

.third-section .owl-carousel .owl-item img {
    display: block;
    width: 40% !important;
}

.bg-sectionnew{
    /* background-image: url('./../../public/assets/img/services-newold.png'); */
    /* background-image: url(http://localhost:3001/static/media/banner-1.44013ee972d0e70f3a6a.png); */
    background-repeat: no-repeat;
    background-image: url('./../../public/assets/img/section-new2.jpg');

    padding: 60px 0px 100px 0px;
}
.maharashtra ul.list-group {
    margin-bottom: 0.3em;
}
.maharashtra .list-group-borderless .list-group-item {
    border: none;
    color: #888888;
    padding: 0;
    padding-bottom: calc(15px / 2);
    background-color: transparent !important;
    border: none;
    font-family: Roboto, sans-serif;
    font-size: inherit;
    font-weight: 400;
    display: flex;
    align-items: center;
}
.maharashtra ul.list-group .list-group-item i {
    padding-right: 10px;
    color: #84b346;
}
.maharashtra ul.list-group .list-group-item:hover {
    color: #84b346;
}
.maha .accordion-body{
    padding: 0px 10px !important;
}
.maha .accordion-item{
    background: transparent !important;
}

.maha .accordion-button::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-plus' viewBox='0 0 16 16'%3E%3Cpath d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3E%3C/svg%3E");
    transition: all 0.5s;
}

.maha .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-dash' viewBox='0 0 16 16'%3E%3Cpath d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/%3E%3C/svg%3E");
}
.maha .accordion-button{
    background: transparent !important;
    border-bottom: 1px solid #80b451;

}
.maha .accordion-button:focus{
    box-shadow: none;
}
.accordion-button:not(.collapsed){
    color: #00aeec;
}

/* .dropdown-item.active{
    background-color: #80b451;
} */

.card-project{
    box-shadow:2px 4px 10px 0px #00000040;
    border-radius: 10px;
}

.four-line{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
.one-line{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .display-grid{
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    gap: 15px;
  }
 @media (max-width:1024px){
    .display-grid{
        grid-template-columns: auto auto auto;
    }
 }
 @media (max-width:768px){
    .display-grid{
        grid-template-columns: auto auto;
    }
    .display-none{
        display: none;
    }
 }
 @media (max-width:500px){
    .display-grid{
        grid-template-columns: auto;
    }
    .width-25{
        width:25% !important;
    }
    .width-75{
        width:75% !important;
    }
    .ban-3{
        padding: 90px 0px;
    }
    .ban-4{
        padding: 90px 0px;
    }
    .ban-1{
        padding: 90px 0px;   
    }
    .width-50{
        width: 50% !important;
    }
    .borad-member-content{
        bottom: 0px !important;
        height: 120px !important;
    }
    .readmore {
        margin-top: 4px;
    }
    .height-auto{
        height: auto !important;
    }
    .height-215{
        height: 150px ;
        height: 215px ;
    }
    .display-none{
        display: none;
    }
   .slick-prev:before{
        display: none;
    }
   .slick-next:before{
        display: none;
    }
 }



 .height-332{
    height: 332px;
 }
 .jammnu .nav-pills .nav-link.active{
    background-color: #84b346;
    color: white;
 }
 .jammnu .nav-pills .nav-link{
    color: #84b346;
 }


.desktopview-slider{
    display: block;
}
.mobileview-slider{
    display: none;
}

@media (max-width:768px){
    .desktopview-slider{
        display: none;
    }
    .mobileview-slider{
        display: block;
    }
    .breadcrum-document {
        padding: 50px 0px; 
    }
    .breadcrum-ongoing {
        padding: 50px 0px; 
    }
    .breadcrum-Announcement{
        padding: 50px 0px; 
    }
    .breadcrum-shareholder{
        padding: 50px 0px; 
    }
    .breadcrum-news{
        padding: 50px 0px; 
    }
    .breadcrum-career{
        padding: 50px 0px; 
    }
    .breadcrum-contact{
        padding: 50px 0px; 
    }
}

.owl-dots button.owl-dot.active {
    background-color: white !important;
    display: none;
}

.pagination .active{
    background-color:#84b346 !important;
    color: white !important;
    border-color:#84b346 !important;
}
.active>.page-link{
    background-color:#84b346 !important;
    color: white !important;
    border-color:#84b346 !important;
}

.dropdown-item.active{
    background-color: white !important;
    color: black !important;
    /* padding-left: 0.5rem; */
}


.navbar-nav .nav-link.active{
    color: #979899 !important;
}

.facebook:hover{
    color: skyblue !important;
}
.instagram:hover{
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%) !important;
    -webkit-background-clip: text;
    background-clip: text !important;
    -webkit-text-fill-color: transparent;
}
.youtube:hover{
    color: red !important;
}
.twitter:hover{
    color: #1c4ea1 !important;
}
.linkedin:hover{
color:#1c4ea1 !important
}